@import url(../node_modules/cropperjs/dist/cropper.css);
.Toastify__toast--success {
  background: #389867 !important; }

.raised-cancel-button {
  background: #db4237 !important;
  color: #fff !important; }

.video-upload-container {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }
  .video-upload-container .video-upload-icom {
    color: rgba(0, 0, 0, 0.54);
    font-size: 10em; }
  .video-upload-container .video-upload-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .video-upload-container .loading-container {
    position: relative;
    width: 100px;
    height: 100px; }
    .video-upload-container .loading-container .text-percentage {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

.video-player-container {
  position: relative; }
  .video-player-container button.video-react-big-play-button.video-react-big-play-button-left {
    display: none !important; }
  .video-player-container .overlay {
    position: absolute;
    width: 802px;
    height: 451px;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 99999999999;
    opacity: 0;
    text-align: center;
    transition: opacity 0.15s ease-in-out 0s;
    transform: scale(0); }
    .video-player-container .overlay .buttons-video-player {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }
      .video-player-container .overlay .buttons-video-player .video-player-icon span {
        font-size: 80px;
        color: #fff;
        margin: 0 20px;
        cursor: pointer; }
  .video-player-container.backdrop:hover .overlay {
    opacity: 1;
    transform: scale(1); }

.video-react:focus {
  outline: none !important; }

.loading-shading-mui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); }

.loading-icon-mui {
  position: absolute;
  font-size: 20px;
  top: calc(45% - 10px);
  left: calc(50% - 10px); }

.DataTableOptions {
  display: inline-block;
  text-align: center;
  width: 100%; }
  .DataTableOptions .option-icons {
    margin: 0 10px;
    cursor: pointer; }
    .DataTableOptions .option-icons.delete {
      color: red; }

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .empty-state-container .empty-state-icon {
    font-size: 200px; }
  .empty-state-container .empty-state-icon,
  .empty-state-container .empty-state-title {
    color: rgba(0, 0, 0, 0.54); }

.attach-publication {
  cursor: pointer; }

.text-filter {
  width: 290px; }

.filter-container {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }
  .filter-container .filter-button {
    background: #387ef5;
    border: none;
    width: 100px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }
    .filter-container .filter-button:focus {
      outline: 0; }

.filter-category {
  margin-right: 10px !important;
  margin-left: 5px !important;
  width: 298px !important;
  padding: 300px; }

.filter-category2 {
  width: 100%;
  flex-direction: row !important; }

.menu-link-active .material-icons {
  color: #387ef5; }

.container-size-fix {
  display: inline-block;
  width: 100%;
  position: relative; }

.option-icons {
  margin: 0 10px;
  cursor: pointer; }
  .option-icons.delete {
    color: red; }

td {
  max-width: 300px !important;
  min-width: 150px !important; }

.filter-item {
  display: inline-block;
  margin: 0 10px;
  min-width: 200px; }
  .filter-item .MuiInputBase-root {
    width: 100%; }
